import React from "react"
import { graphql } from "gatsby"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import EMailIcon from "@material-ui/icons/MailOutline"

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "center",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingBottom: "30px",
    backgroundColor: "#fff",
  },
  title: {
    marginTop: 0,
    paddingTop: "1rem",
  },
  subtitle: {
    marginTop: "2rem",
    marginBottom: "0.5rem",
  },
  a: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  inlineIcon: {
    position: "relative",
    top: "3px",
    width: theme.typography.body1.fontSize,
    height: theme.typography.body1.fontSize,
  },
  content: {
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      color: theme.palette.primary.dark,
    },
    "& ul": {
      listStyleType: "circle",
    },
    "& ul p": {
      margin: 0,
      padding: 0,
    },
  },
}))

const ContactPage = ({ data, location }) => {
  const classes = useStyles()
  const { twitter } = data // data.markdownRemark holds your post data

  return (
    <Layout dark>
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          Contact Us
        </Typography>
        <Typography variant="body1">
          Hi! We're happy to hear from you and make sure your experience with
          Simpleen is an excellent one.
          <br />
          <br />
          We continuously improve Simpleen to make is as useful and compatible
          as possible. <br />
        </Typography>
        <a href="mailto:hello@simpleen.io" className={classes.a}>
          <EMailIcon className={classes.inlineIcon} /> hello@simpleen.io
        </a>

        <Typography variant="h2" className={classes.subtitle}>
          Bug Report
        </Typography>
        <Typography variant="body1">
          Thanks for reporting and helping improving Simpleen. If you've
          experienced a bug with the Simpleen CLI you can report it in the{" "}
          <a
            href="https://github.com/daugsbi/simpleen-cli"
            className={classes.a}
          >
            corresponding github repository
          </a>
          .<br />
          <br />
          All other bugs can direclty reported by email to <br />
          <a href="mailto:denis.augsburger@simpleen.io" className={classes.a}>
            denis.augsburger@simpleen.io
          </a>
        </Typography>

        <Typography variant="h2" className={classes.subtitle}>
          Feature Requests
        </Typography>
        <Typography variant="body1">
          We collect and prioritize feature requests from users/customers and
          put it on our roadmap. <br />
          Just drop us an email and we keep you informed how we're doing <br />
          <br />
          <a href="mailto:hello@simpleen.io" className={classes.a}>
            <EMailIcon className={classes.inlineIcon} /> hello@simpleen.io
          </a>
        </Typography>
      </Container>
      <Seo
        general={{
          title: "Contact us - simpleen.io",
          description: "Get directly in touch with us",
          language: "en",
          path: location.pathname,
          image: twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const privacyQuery = graphql`
  query SimpleenContactQuery {
    twitter: file(relativePath: { eq: "seo/SEOImageFormat.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
  }
`

export default ContactPage
